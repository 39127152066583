import React from "react"
import Meta from "../components/meta"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Title from "../components/title"
import Back from "../components/back"

export default function Home() {
  return (
    <>
      <Meta title="IT トレーニング講習" description="お客様のスキル・ニーズに合わせたプログラミング体験セミナーを開催します。" />
      <div className="flex flex-col h-screen">
        <header>
          <Navbar hasContact={false} />
        </header>
        <main className="mt-14 reveal fade-bottom">
          <div className="container max-w-5xl mx-auto py-4 px-4">
            <Title title="IT トレーニング講習" />

            <div className="bg-white rounded-lg mb-4 flex flex-wrap">
              <div className="w-full p-6">
                <h3 className="card-heading text-xl md:text-3xl font-bold leading-none">
                  もしも Excel マクロが使えたら
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  ビジネスパーソンであれば一度は思ったことがあるはず。毎月の締め作業や入力、繰り返し行う手作業は卒業しましょう！
                </p>

                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  プログラムスキルは 2020 年からの小学校でのプログラミング教育の必修化もあり、これからの時代は英語に続く必須スキルとなりつつあります。
                </p>

                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  お客様のスキル・ニーズに合わせたプログラミング体験セミナーを開催します。様々な PC の設定に慣れたりプログラムの仕組みや原理の理解、VBA に馴染みがある方のステップアップなど、現役エンジニアが教えます。お気軽にご相談ください。
                </p>
                <Back />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}
